<template>
  <v-card :color="cardColor">
    <!-- Nästlade v-cards möjliggör den färgade kanten på korten i nyhetsflödet -->
    <v-card class="my-0 mr-0 ml-1 pa-0 inner-card">
      <!--
      Om "image" inte är tom och alternativet
      "bigimg" valts visas bilden "image" här
      -->
      <v-img
        class="white--text align-end ma-0 pa-0"
        :src="image"
        v-if="image && (bigimg || $vuetify.breakpoint.xsOnly)"
      >
      </v-img>
      <!--
        Om nyheten är ett sök eller ett event med ett datum
        eller en sökperiod kommer dessa visas som overline
      -->
      <div class="mx-4 pt-4" v-if="eventtime || location">
        <div class="overline" v-if="eventtime !== ''">
          {{
            (language == "eng" ? "When: " : "När: ") +
            prettyDateString(eventtime)
          }}
        </div>
        <div class="overline" v-if="location !== ''">
          {{
            (language == "eng" ? "Where: " : "Var: ") +
            (language == "eng" && location_en ? location_en : location)
          }}
        </div>
        <v-divider></v-divider>
      </div>
      <v-card-title
        class="text-h4 mb-0 pb-0"
        v-text="title_en && language === 'eng' ? title_en : title"
      ></v-card-title>

      <!--
      Om "image" inte är tom och alternativet
      "bigimg" inte valts visas bilden "image" här
      -->
      <v-img
        v-if="image && !(bigimg || $vuetify.breakpoint.xsOnly)"
        contain
        class="ma-5 right"
        max-width="45%"
        style="border-radius: 4px"
        :src="image"
      />
      <v-card-subtitle
        class="mt-3"
        v-text="subtitle_en && language === 'eng' ? subtitle_en : subtitle"
      ></v-card-subtitle>

      <div class="overline mx-4">
        <v-divider class="my-3" />
        <div v-if="publishedtime">
          {{
            (language == "eng" ? "Published: " : "Publicerad: ") +
            prettyDateString(publishedtime)
          }}
        </div>
        <div v-if="hasForm">{{ formTimeString }}</div>
      </div>

      <v-card-actions v-if="(hasForm || hasLongText)" class="mt-0 pt-0 clear">
        <!--
        I fallet av ett sök eller ett event med anmälan
        visas en knapp med länk till söket respektive anmälan
        -->
        <v-btn
          text
          v-if="form_id && form_id !== '0' && (formStatus === 'open' || formStatus === 'closing')"
          :href="
            form_id.includes('http')
              ? form_id
              : 'https://forms.futf.se/form/' + form_id
          "
          :to="category == 'application' ? '/engageradig' : undefined"
          :color="cardColor"
        >
          {{ formButtonText }}
        </v-btn>
        <!-- Om mer text finns visas en knapp som gör att en dropp-down med denna text visas -->
        <v-btn v-if="hasLongText" text @click="show = !show">
          {{ language === "swe" ? "Läs mer" : "Read more" }}
          <v-icon right>
            {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text
            v-html="text_en && language === 'eng' ? text_en : text"
          />
        </div>
      </v-expand-transition>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      show: false
    }
  },
  props: { // These props MUST reflect the backend event model. Do not touch
    id: Number,
    title: String,
    title_en: String,
    subtitle: String,
    subtitle_en: String,
    text: String,
    text_en: String,
    category: String,
    eventtime: String,
    publishedtime: String,
    location: String,
    location_en: String,
    image: String,
    bigimg: Boolean,
    form_id: String,
    form_opentime: String,
    form_closetime: String,
    created_at: String,
    language: String
    // The language parameter is not part of the event data model, it's just here
    // so that we don't need to check with Vuex for every card
  },
  computed: {
    hasLongText: function () {
      return (this.text_en && this.language === 'eng') || this.text
    },
    hasForm: function () {
      return this.form_id && parseInt(this.form_id) !== 0
    },
    cardColor: function () {
      var dark = this.$vuetify.theme.dark
      if (this.category === 'application') {
        return dark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary
      } else if (this.category === 'event') {
        return dark ? this.$vuetify.theme.themes.dark.eventcolor : this.$vuetify.theme.themes.light.eventcolor
      } else {
        return dark ? this.$vuetify.theme.themes.dark.newscolor : this.$vuetify.theme.themes.light.newscolor
      }
    },
    formTimeString: function () {
      if (!this.hasForm) {
        return ''
      }
      var s = ''
      if (this.language === 'swe') {
        switch (this.category) {
          case 'event':
            s += 'Anmälan '
            break
          case 'news':
            s += 'Formuläret '
            break
          case 'application':
            s += 'Söket '
        }
      } else {
        switch (this.category) {
          case 'event':
            s += 'The application '
            break
          case 'news':
            s += 'The form '
            break
          case 'application':
            s += 'The application '
        }
      }
      switch (this.formStatus) {
        case 'opens':
          if (this.language === 'swe') {
            s += 'öppnar den '
          } else {
            s += 'opens the '
          }
          s += this.prettyDateString(this.form_opentime)
          break
        case 'open':
          if (this.language === 'swe') {
            s += this.category === 'event' ? 'är öppen!' : 'är öppet!'
          } else {
            s += 'is open!'
          }
          break
        case 'closing':
          if (this.language === 'swe') {
            s += this.category === 'event' ? 'är öppen tills ' : 'är öppet tills '
          } else {
            s += 'is open until '
          }
          s += this.prettyDateString(this.form_closetime)
          break
        case 'closed':
          if (this.language === 'swe') {
            s += 'har stängt!'
          } else {
            s += 'has closed!'
          }
          break
      }
      return s
    },
    formButtonText: function () {
      if (this.language === 'eng') {
        switch (this.category) {
          case 'event':
            return 'To the registration'
          case 'news':
            return 'To the form'
          case 'application':
            return 'To the application'
        }
      } else {
        switch (this.category) {
          case 'event':
            return 'Till anmälan'
          case 'news':
            return 'Till formuläret'
          case 'application':
            return 'Till söken'
        }
      }
      return ''
    },
    formStatus: function () {
      // Computes whether a form is yet to open, is open, will close, or has closed. Returns:
      // 'opens'   - will open in future
      // 'open'    - currently open, no closing date
      // 'closing' - currently open, will close
      // 'closed'  - currently closed

      if (!this.hasForm) {
        return undefined
      }
      const current = new Date()
      if (this.form_opentime) {
        const opentime = new Date(this.form_opentime)
        const hasopened = opentime < current
        if (!hasopened) {
          return 'opens'
        }
      }
      // If code reaches this point, form is or was at some point open
      if (this.form_closetime) {
        const closetime = new Date(this.form_closetime)
        const hasclosed = closetime < current
        if (hasclosed) {
          return 'closed'
        } else {
          return 'closing'
        }
      }
      return 'open'
    }
  },
  methods: {
    prettyDateString: function (date) {
      date = new Date(date)
      return date.toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
    }
  }
}
</script>

<style>
.clear {
  clear: right;
}
.right {
  float: right;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.inner-card {
  border-radius: 1px 4px 4px 1px
}
</style>
