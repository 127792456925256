<template>
  <v-container fluid class="pa-1">
    <v-expansion-panels v-model="activePanel">
      <v-expansion-panel v-for="yearPosts in postsNew" :key="yearPosts.year">
        <v-expansion-panel-header>
          <v-row>
            <v-col cols="2">
              {{yearPosts.year}}
            </v-col>
            <v-col>
              {{numberOfPostsString(yearPosts)}}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" v-for="post in yearPosts.posts" :key="post.id" :id="'post_' + post.id" class="px-0">
              <news-card
                v-bind="post"
                :language="currentLang"
              ></news-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import NewsCard from '@/components/Newscard.vue'
import axios from 'axios'

export default {
  data: () => {
    return {
      data: [],
      currentYear: undefined,
      activePanel: undefined,
      yearToPanelIndex: {},
      eventIDToYear: {},
      postsByID: {}
    }
  },
  props: {
    eventID: String
  },
  components: {
    NewsCard
  },
  created: function () {
    const now = new Date()
    this.currentYear = now.getFullYear()
    // this.data = require('@/../db.json').data
    axios
      .get('/api/events/all')
      .then((result) => {
        this.data = result.data
      })
  },
  computed: {
    currentLang: function () {
      return this.$store.getters.lang
    },
    postsNew: function () {
      const postsByYear = {} // Object{year -> Array[posts]}
      for (let i = 0; i < this.data.length; i++) {
        const post = this.data[i]
        const postTime = new Date(this.getPostTime(post))
        const postYear = postTime.getFullYear()
        if (!(postYear in postsByYear)) {
          postsByYear[postYear] = [post]
        } else {
          postsByYear[postYear].push(post)
        }
        this.addEventIDToYear(post.id, postYear)
        this.addPostByID(post.id, post)
      }
      const postsByYearArray = Object.entries(postsByYear).map((e) => ({ year: e[0], posts: e[1] })) // Array[{year, posts}]
      postsByYearArray.sort((a, b) => (b.year > a.year) ? 1 : -1) // sort by year (descending order)
      for (let i = 0; i < postsByYearArray.length; i++) {
        postsByYearArray[i].posts.sort((a, b) => new Date(this.getPostTime(b)) - new Date(this.getPostTime(a))) // in every year, sort by date (descending order)
        this.addYearToPanelIndex(postsByYearArray[i].year, i)
      }

      return postsByYearArray
    },
    posts: function () {
      const postsObjs = [{ year: this.currentYear, posts: [] }]
      let year = this.currentYear
      var j = 0
      for (let i = 0; i < this.data.length; i++) {
        const post = this.data[i]
        const postTime = new Date(this.getPostTime(post))
        const postYear = postTime.getFullYear()
        if (postYear < year) {
          j = j + 1
          year = postYear
          postsObjs[j] = { year: postYear, posts: [] }
        }
        postsObjs[j].posts.push(post)
      }
      return postsObjs
    }
  },
  watch: {
    postsNew: function (newValue) {
      if ((this.eventID !== undefined) && (this.eventID in this.eventIDToYear)) {
        this.activePanel = this.yearToPanelIndex[this.eventIDToYear[this.eventID]] // open the panel with the event in it
        setTimeout(() => {
          this.$vuetify.goTo(`#post_${this.eventID}`) // CSS selectors can't handle ids starting with a digit so we add "post_" before it.
        }, 500) // add delay so that the expansion panel has time to open
      }
    }
  },
  methods: {
    getPostTime: function (post) {
      return post.publishedtime
    },
    numberOfPostsString: function (yearPosts) {
      if (yearPosts.posts.length === 1) {
        return yearPosts.posts.length + (this.currentLang === 'swe' ? ' artikel' : ' article')
      } else {
        return yearPosts.posts.length + (this.currentLang === 'swe' ? ' artiklar' : ' articles')
      }
    },
    addYearToPanelIndex (year, index) {
      this.yearToPanelIndex[year] = index
    },
    addEventIDToYear (eventID, year) {
      this.eventIDToYear[eventID] = year
    },
    addPostByID (eventID, post) {
      this.postsByID[eventID] = post
    }
  }
}
</script>
